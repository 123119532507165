export const capitalizeString = (application: string | undefined): string => {
    if (!application) {
        return '';
    }

    let words = application.split(' ');

    let capitalizedWords = words.map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    let capitalizedSentence = capitalizedWords.join(' ');

    return capitalizedSentence;
};


export const decapitalizeString = (application: string | undefined) => {
    let words = application?.split(' ');

    let decapitalizedWords = words?.map(function (word) {
        if (word.includes('-')) {
            return word
                .split('-')
                .map((part) => part.charAt(0).toLowerCase() + part.slice(1))
                .join('-');
        } else {
            return word.charAt(0).toLowerCase() + word.slice(1);
        }
    });

    let decapitalizedSentence = decapitalizedWords?.join(' ');

    return decapitalizedSentence;
};
