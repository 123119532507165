import Filter from './Filter';
import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';

const FilterSelection = ({
                             availableColours,
                             availableDesigns,
                             availableTypes,
                             handleCheckboxChange,
                             filterByAllProperties,
                             colourList,
                             setColourList,
                             designList,
                             setDesignList,
                             typesList,
                             setTypesList,
                             applicationsList,
                             anchorRef,
                             setCurrentPage,
                             location,
                             navigate,
                             itemsPerPage,
                         }: any) => {
    const [showDesignStyle, setShowDesignStyle] = useState<boolean>(true);
    const [showColour, setShowColour] = useState<boolean>(true);
    const [showTypes, setShowTypes] = useState<boolean>(true);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        if (isMobile) {
            setShowColour(false);
            setShowDesignStyle(false);
            setShowTypes(false);
        }
    }, [isMobile]);

    const handleApplyFilters = () => {
        // Build the URL parameters from the state variables
        const currentParams = new URLSearchParams();

        applicationsList.forEach((application: string) => {
            currentParams.append('application', application);
        });

        colourList.forEach((colour: string) => {
            currentParams.append('colours', colour);
        });

        designList.forEach((design: string) => {
            currentParams.append('design_style', design);
        });

        typesList.forEach((type: string) => {
            currentParams.append('types', type);
        });

        // Set pagination parameters
        currentParams.set('page', '1');
        currentParams.set('startoffset', '0');
        currentParams.set('endoffset', itemsPerPage.toString());

        // Navigate to the new URL
        navigate(`${location.pathname}?${currentParams.toString()}`);

        // Scroll to the top of the filter component
        if (anchorRef && anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65,
                behavior: 'smooth',
            });
        }

        // Reset current page
        setCurrentPage(1);
    };

    return (
        <>
            {/* Colour Filter */}
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography fontWeight="bold" fontSize="20px" mb={2}>
                    Colour
                </Typography>
                <Box
                    onClick={() => {
                        setShowColour(!showColour);
                    }}
                    sx={{ cursor: 'pointer' }}
                >
                    {showColour ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </Box>
            </Box>
            {showColour && (
                <Filter
                    filterType={availableColours}
                    handleCheckboxChange={handleCheckboxChange}
                    list={colourList}
                    setList={setColourList}
                    keyName="colours"
                    paramKey="colours"
                />
            )}

            {/* Design Filter */}
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography fontWeight="bold" fontSize="20px" mb={2}>
                    Design
                </Typography>
                <Box
                    onClick={() => {
                        setShowDesignStyle(!showDesignStyle);
                    }}
                    sx={{ cursor: 'pointer' }}
                >
                    {showDesignStyle ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </Box>
            </Box>
            {showDesignStyle && (
                <Filter
                    filterType={availableDesigns}
                    handleCheckboxChange={handleCheckboxChange}
                    list={designList}
                    setList={setDesignList}
                    keyName="design_style"
                    paramKey="design_style"
                />
            )}

            {/* Types Filter */}
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography fontWeight="bold" fontSize="20px" mb={2}>
                    Types
                </Typography>
                <Box
                    onClick={() => {
                        setShowTypes(!showTypes);
                    }}
                    sx={{ cursor: 'pointer' }}
                >
                    {showTypes ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </Box>
            </Box>
            {showTypes && (
                <Filter
                    filterType={availableTypes}
                    handleCheckboxChange={handleCheckboxChange}
                    list={typesList}
                    setList={setTypesList}
                    keyName="types"
                    paramKey="types"
                />
            )}

            {/* Apply Button */}
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />

            <Button
                variant="contained"
                onClick={handleApplyFilters}
                sx={{
                    width: { xs: '100%', lg: '50%' },
                }}
                disableElevation
            >
                Apply
            </Button>

            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
        </>
    );
};

export default FilterSelection;
