// ApplicationsTemplate.jsx

import { useQuery } from 'react-query';
import { useParams, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';
import { apiGetApplicationPageData } from '../../../api/apiApplications';
import PageLayout from '../layout/PageLayout';
import CarouselBanner from '../banner/CarouselBanner';
import TextHeader from '../textHeader/TextHeader';
import NewArrivalsGrid from '../grid/NewArrivalsGrid';
import FilterComponent from '../filter/FilterComponent';
import LoadingScreen from '../loadingScreen/LoadingScreen';
import FeaturedGallery from '../gallery/FeaturedGallery';
import { capitalizeString } from '../../../utilities/TextManipulation';
import MissingPage from '../errorMessages/MissingPage';
import CatalogueGrid from '../grid/CatalogueGrid';
import CollectionsGrid from '../grid/CollectionsGrid';
import { catalogueData } from '../../../api/tempData';
import { captureAnalytics } from '../../../utilities/Analytics';

function ApplicationsTemplate() {
    const { item_group, application } = useParams<{ item_group?: string; application?: string }>();
    const location = useLocation();
    const navigationType = useNavigationType();

    const argumentDataStructure = {
        category: { application: application ?? '', item_group: item_group ?? '' },
    };

    const {
        data: applicationData,
        isLoading,
        isError,
    } = useQuery(
        ['application', application],
        () => apiGetApplicationPageData(argumentDataStructure),
        { staleTime: 0, enabled: !!application }
    );

    // Scroll management: Scroll to top when pathname changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // Track page view for analytics
    useEffect(() => {
        if (application) {
            captureAnalytics('pageview', location.pathname, `application-${application}`);
        }
    }, [location.pathname, application]);

    if (!application) {
        return <MissingPage />;
    }

    return (
        <PageLayout>
            <>
                {isLoading && <LoadingScreen />}
                {isError && <MissingPage />}
                {applicationData && (
                    <>
                        <CarouselBanner
                            images={applicationData.section_hero}
                            video={applicationData.section_hero_video?.video_url}
                        />

                        <TextHeader
                            heading={applicationData.page_title}
                            content={applicationData.description}
                            isCentered={false}
                            width="70%"
                            marginTop={10}
                            marginBottom={5}
                        />

                        <FeaturedGallery
                            images={applicationData.section_featured_collection}
                            hasVideo={false}
                            invert={false}
                            marginTop={5}
                            marginBottom={5}
                            title={applicationData.featured_collection_title?.collection_name}
                            description={applicationData.featured_collection_description}
                            itemGroup={applicationData.featured_collection_title?.item_group_code}
                            application={applicationData.featured_collection_title?.application_name}
                            collection={applicationData.featured_collection_title?.collection_name}
                        />

                        <FilterComponent
                            itemGroup={item_group}
                            title={capitalizeString(application)}
                        />

                        <CollectionsGrid
                            data={applicationData.section_collections_in_application}
                            applicationName={application}
                            marginTop={5}
                            marginBottom={5}
                        />

                        <NewArrivalsGrid
                            header="New arrivals"
                            data={applicationData.section_new_arrivals}
                            hasTitles={true}
                            hasButtons={true}
                            hasBackgroundText={false}
                            spacingValue={4}
                            marginTop="5rem"
                            marginBottom="5rem"
                        />

                        <CatalogueGrid
                            header="View our latest catalogues"
                            catalogueData={catalogueData}
                        />
                    </>
                )}
            </>
        </PageLayout>
    );
}

export default ApplicationsTemplate;
